<template>
    <div class="page-box">
        <div class="container">
            <div class="header">
                <img src="http://images.yungouos.com/wxpay/cashier/wxpay_logo.png"/>
            </div>
            <vue-qr v-if="codeImgUrl" class="mtb10" :text="codeImgUrl" :size="300" :margin="10"></vue-qr>
            <div class="tips">
                <img src="@/assets/wx_icon.png"/>
                <p>请使用微信扫描<br/>二维码以完成支付</p>
            </div>
            <van-divider class="divider" :hairline="false" :style="{ color: '#ccc', borderColor: '#ccc', padding: '10px 16px' }">
                <img src="@/assets/cart_icon.png"/>
            </van-divider>
            <div class="amount">￥ {{orderInfo.amount/100}}</div>
            <div class="order-info">
                <div class="cell">支付订单号</div>
                <div class="cell">{{orderInfo.payOrderId}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import VueQr from 'vue-qr'
import {unifiedOrderQuery} from '@/api/api'
export default {
    name:"Cashier",
    components:{VueQr},
    data: function (){
        return {
            codeImgUrl:undefined,
            timer:undefined,
            orderInfo:{}
        }
    },
    created(){
        let params = this.$route.query;
        this.codeImgUrl = encodeURI(params.codeImgUrl)+this.$route.hash;
        this.orderTask(params.mchOrderNo,params.payOrderId)
    },
    beforeDestroy(){
        if(this.timer){
            window.clearInterval(this.timer);
        }
    },
    methods:{
        orderTask(mchOrderNo,payOrderId){
            if(this.timer){
                window.clearInterval(this.timer);
                this.timer=undefined;
            }
            this.timer = window.setInterval(()=>{
                unifiedOrderQuery({mchOrderNo,payOrderId}).then(res=>{
                    this.orderInfo = res;
                    if(res.state==0||res.state==1){
                        return;
                    }
                    if(res.state==2){
                        this.showDialog=false;
                        window.clearInterval(this.timer);
                        this.$router.push({ path: "/order?mchOrderNo=" + res.mchOrderNo + "&payOrderId="+res.payOrderId}).catch(() => { });
                       return;
                    }
                    if(res.state==3){
                       this.showDialog=false;
                       alert("支付失败")
                       window.clearInterval(this.timer);
                    }
                    if(res.state==4){
                        this.showDialog=false;
                        alert('已撤销');
                        window.clearInterval(this.timer);
                    }
                    if(res.state==5){
                       this.showDialog=false;
                       alert('已退款');
                        window.clearInterval(this.timer);
                    }
                    if(res.state==6){
                       this.showDialog=false;
                       alert('订单关闭');
                       window.clearInterval(this.timer);
                    }
                    this.$router.push({ path: "/demo"}).catch(() => {});
                }).catch((err)=>{
                    alert(err);
                })
            },1000);
        }
    }
}
</script>
<style scoped>
.page-box{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    height: 100vh;
    background-image: url(https://cashier.yungouos.com/assets/6ecf79dbb9a59248510d64773993254e.jpg);
}
.page-box .container{
    margin: 0 auto;
    margin-top: 10px;
    width: 50%;
    height: 80%;
    background-color: #fff;
}
.page-box .container .header{
    height: 35px;
    line-height: 35px;
    background-color: #00ae47;
}
.page-box .container .header img{
    vertical-align: middle;
    width: 55px;
    height: 25px;
}
.tips{
    background-color: #00ae47;
    border: 1px solid #00ae47;
    border-radius: 3px;
    color: #fff;
    padding: 1px 0;
    font-size: 7px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 110px;
    margin: 0 auto;
}
.tips img{
    width: 25px;
    height: 25px;
    vertical-align: middle;
    padding: 3px 2px;
}
.amount{
    height: 40px;
    line-height: 40px;
    border-bottom: 0.5px solid #ccc;
    font-size: 12px;
}
.order-info{
    display: flex;
    justify-content: space-between;
    height: 35px;
    line-height: 25px;
    font-size: 7px;
}
.mtb10{
    margin-top:10px;
    margin-bottom: 10px;
}
@media screen and (max-width:1336px){
    .page-box .container{
        margin: 0 auto;
        margin-top: 10px;
        width: 95%;
    }
    .page-box .container .header{
        height: 65px;
        line-height: 65px;
    }
    .page-box .container .header img{
        width: 105px;
        height: 45px;
    }
    .tips{
        color: #fff;
        font-size: 14px;
        text-align: center;
        display: flex;
        justify-content: center;
        width: 200px;
    }
    .tips img{
        width: 45px;
        height: 45px;
        vertical-align: middle;
        padding: 10px 10px;
    }
    .amount{
        font-size: 25px;
    }
    .order-info{
        font-size: 14px;
    }
}
</style>